import Link from 'components/elements/Link'
import React from 'react'
import Icon from 'components/elements/Icon'
import { tagNameToUrl } from 'utils/deckTagAliases'

import styles from './tagInfoTable.module.scss'

export type TagChanges = {
  id: number
  name: string
  weeklyQuantity: string
  differencePercent: string
}

type Props = {
  tagChanges: TagChanges[]
  infoColumn: 'weekly' | 'increase' | 'decrease'
}

const TagInfoTable = ({ tagChanges, infoColumn }: Props) => (
  <div>
    <h4>
      {infoColumn === 'weekly' && 'Top weekly tags'}
      {infoColumn === 'increase' && 'Top increased tags'}
      {infoColumn === 'decrease' && 'Top decreased tags'}
    </h4>
    <div className={styles.table}>
      {tagChanges.map((data, index) => (
        <div className={styles.row} key={index}>
          <Link href={`/tags/${tagNameToUrl(data.name)}`} className={styles.tagName}>
            {data.name}
          </Link>

          <span>
            {infoColumn === 'weekly' && <span className={styles.data}>{data.weeklyQuantity} decks</span>}
            {infoColumn === 'increase' && (
              <span className={styles.data}>
                <Icon name="arrow circle up" />
                <div className={styles.percent}>{data.differencePercent}%</div>
              </span>
            )}
            {infoColumn === 'decrease' && (
              <span className={styles.data}>
                <Icon name="arrow circle down" />
                <div className={styles.percent}>{data.differencePercent}%</div>
              </span>
            )}
          </span>
        </div>
      ))}
    </div>
  </div>
)

export default TagInfoTable
