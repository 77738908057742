import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import Icon from 'components/elements/Icon'

import DeckService from 'services/deck.service'
import ToastService from 'services/toast.service'

import { CardType, FORMAT } from 'types/deck'

import Link from 'components/elements/Link'
import SearchedCard from 'components/cardSearchPanel/cardLayouts/searchedCard'
import Header from 'components/userPage/Header'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import AddToOtherDeck from 'components/card/cardForm/addToOtherDeck'

import { commanderNameToUrl } from 'utils/commanderNameAliases'
import { generateDeckUrl } from 'utils/deckSeo'

import styles from './topCardsGrid.module.scss'

type Props = {
  cards: CardType[]
  quantities: Record<string, number>
  title: string
  className?: string
  commanders?: boolean
}

const TopCardsGrid = ({ cards, quantities, title, className, commanders }: Props) => (
  <div className={className}>
    <Header main={title} />
    <div className={styles.cardGrid}>
      {cards.map((card, index) => (
        <Card commanders={commanders} card={card} quantity={quantities[card.oracleCardId]} key={index} />
      ))}
    </div>
  </div>
)

export default TopCardsGrid

const Card = ({ card, quantity, commanders }: { card: CardType; quantity: number; commanders?: boolean }) => {
  const router = useRouter()

  const [{ tbId: userId, tbRootFolderId: rootFolderId }] = useCookies(['tbId', 'tbRootFolderId'])

  const [loading, setLoading] = useState(false)
  const [addToOtherDeck, setAddToOtherDeck] = useState(false)

  const handleCreateNewDeckWithCard = () => {
    let id = 0
    const body = {
      name: `Untitled ${card.name} deck`,
      deckFormat: commanders ? FORMAT.EDH : FORMAT.CUSTOM,
      parentFolder: rootFolderId,
      private: false,
    }

    setLoading(true)

    DeckService.create(body)
      .then(deck => {
        id = deck.id

        return DeckService.add(id, [{ ...card, qty: 1, categories: commanders ? ['Commander'] : [] }])
      })
      .then(() => router.push(generateDeckUrl(id, body.name)))
      .catch(err => {
        console.error(err)

        ToastService.create('Unable to create new deck', 'Deck Service')
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.card}>
      {/* <div className={styles.gradient} /> */}

      <SearchedCard className={styles.basicCard} card={card} onCardClick={() => null} hidePrices />

      <div className={styles.controls}>
        <div className={styles.spacer} />

        <div className={styles.quantities}>{quantity} decks</div>

        {!userId ? (
          <Link href={`/sandbox?commanderAs=${card.name}`}>
            <Icon name="add" /> Create new deck
          </Link>
        ) : (
          <button onClick={handleCreateNewDeckWithCard} disabled={loading}>
            {loading ? (
              <>
                <SimpleSpinner size="xSmall" /> Creating new deck
              </>
            ) : (
              <>
                <Icon name="add" /> Create new deck
              </>
            )}
          </button>
        )}

        {!userId ? (
          <Link href={`/login?redirectTo=${encodeURIComponent(`/cardImport?c=1 ${card.name}`)}`}>
            <Icon name="add" />
            Add to existing deck
          </Link>
        ) : (
          <>
            <button onClick={() => setAddToOtherDeck(true)}>
              <Icon name="add" />
              Add to existing deck
            </button>
            <AddToOtherDeck
              card={card}
              open={addToOtherDeck}
              onClose={() => setAddToOtherDeck(false)}
              parentControlled
            />
          </>
        )}

        <Link
          href={
            commanders
              ? `/commanders/${commanderNameToUrl(card.name)}`
              : `/search/decks/?cardName=${encodeURIComponent(card.name)}`
          }>
          <Icon name="search" /> View all decks
        </Link>
      </div>
    </div>
  )
}
