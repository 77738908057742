import React from 'react'

import { NewsArticleType } from 'types/news'

import Header from 'components/userPage/Header'
import NewsCard from 'components/newsPage/NewsCard'

import styles from './newsGroup.module.scss'

export const grid = styles.grid

type Props = {
  news: NewsArticleType[]
  className?: string
}

const NewsGroup = ({ news, className }: Props) => {
  if (!news.length) return null

  return (
    <div className={className}>
      <Header main="News" secondary="View all news" href="/news" />
      <div className={styles.grid}>
        {news.map((article, index) => (
          <NewsCard key={index} article={article} />
        ))}
      </div>
    </div>
  )
}

export default NewsGroup
