import React from 'react'
import Link from 'components/elements/Link'
import Icon from 'components/elements/Icon'

import { Thread } from 'services/apiTypes/comment.types'

import Header from 'components/userPage/Header'
import { grid } from 'components/homePage/NewsGroup'

import styles from './featuresGroup.module.scss'

type TopFeatureProps = {
  features: Thread[]
  className?: string
}

const TopFeatures = ({ features, className }: TopFeatureProps) => {
  return (
    <div className={className}>
      <Header main="Feature Voting" secondary="View feature requests" href="/features" />
      <div className={grid}>
        {features.map((feature, index) => (
          <FeatureLink key={index} feature={feature} />
        ))}
      </div>
    </div>
  )
}

type FeatureLinkProps = {
  feature: Thread
}

const FeatureLink = ({ feature }: FeatureLinkProps) => (
  <Link href={`/forum/thread/${feature?.id}`} className={styles.card}>
    <div className={styles.grid} />
    <img src="/images/sketch4.svg" alt="sketch" height="200" className={styles.image} />
    <div className={styles.title}>{feature.title}</div>
    <div className={styles.description}>{feature.description}</div>
    <div className={styles.points}>
      <span>
        <Icon name="thumbs up" /> {feature.points}
      </span>
    </div>
  </Link>
)

export default TopFeatures
