import React from 'react'
import Icon from 'components/elements/Icon'

import { DeckCardInfoType } from 'types/search'

import DeckLink, { gridClass } from 'components/misc/DeckLink'
import Header from 'components/userPage/Header'

import styles from './deckGroup.module.scss'

type Props = {
  decks: DeckCardInfoType[]
  title: string
  secondary?: string
  href?: string
  loading?: boolean
  className?: string
  gridClassName?: string
  includeContestWinnerIcons?: boolean
  noTags?: boolean
}

const DeckGroup = ({
  title,
  href,
  decks,
  secondary = 'View decks',
  loading,
  className,
  gridClassName,
  includeContestWinnerIcons,
  noTags,
}: Props) => {
  if (!decks?.length) return null

  return (
    <div className={className}>
      {href && <Header main={title} secondary={secondary} href={href} />}
      {!href && <Header main={title} />}
      <div className={gridClassName ?? gridClass}>
        {decks.map((deck, index) => {
          if (!includeContestWinnerIcons)
            return <DeckLink key={index} index={index} deck={deck} loading={loading} noTags={noTags} />

          return <ContestWinnerDeckLinks key={index} deck={deck} index={index} loading={loading} noTags={noTags} />
        })}
      </div>
    </div>
  )
}

export default DeckGroup

const CONTEST_WINNER_COLORS = [
  { color: '#fde39b', backgroundColor: '#daa30c' },
  { color: '#636262', backgroundColor: '#bebebe' },
  { color: '#fd9855', backgroundColor: '#996b4c' },
]

const ContestWinnerDeckLinks = ({
  deck,
  index,
  loading,
  noTags,
}: {
  deck: DeckCardInfoType
  index: number
  loading?: boolean
  noTags?: boolean
}) => (
  <div className={styles.container}>
    <DeckLink index={index} deck={deck} loading={loading} noTags={noTags} />
    <span
      className={styles.trophy}
      style={{
        color: CONTEST_WINNER_COLORS[index].color,
        backgroundColor: CONTEST_WINNER_COLORS[index].backgroundColor,
      }}>
      <Icon name="trophy" />
    </span>
  </div>
)
