import React from 'react'
import { GetServerSideProps } from 'next'

import environment from 'environment'

import { DeckCardInfoType } from 'types/search'
import { CardType } from 'types/deck'

import { RequestService } from 'services/request.service'

import PageWrapper from 'components/misc/PageWrapper'
import Landing from 'components/homePage/Landing'
import DeckGroup from 'components/homePage/DeckGroup'
import NewsGroup from 'components/homePage/NewsGroup'
import FeaturesGroup from 'components/homePage/FeaturesGroup'
import InlineAd from 'components/advertisments/InlineAd'
import TagInfoTable, { TagChanges } from 'components/communityPage/TagInfoTable'
import TopCardsGrid from 'components/communityPage/TopCardsGrid'
import Tabs from 'components/homePage/Tabs'
import CustomAd from 'components/advertisments/CustomAd'

import { responseToCard } from 'utils/ResponseToResults'
import { DEFAULT_DESCRIPTION } from 'utils/DefaultDescription'
import { LOG_ERROR_AND_REDIRECT } from 'utils/ErrorString'
import { tagNameToUrl } from 'utils/deckTagAliases'

import styles from './community.module.scss'

type Community = {
  contentCreatorDecks: DeckCardInfoType[]
  contestWinnerDecks: DeckCardInfoType[]
  featuredFormatDecks: DeckCardInfoType[]
  featuredTagDecks: DeckCardInfoType[]

  topCards: CardType[]
  topCommanders: CardType[]

  contestTitle: string | null

  contentCreatorRole: { id: number; name: string }
  featuredFormat: { id: number; name: string }
  featuredTag: { id: number; name: string }

  topCardQuantities: Record<string, number>
  topCommanderQuantities: Record<string, number>

  tagChangesOverTime: {
    topWeeklyTags: TagChanges[]
    topIncreasedTags: TagChanges[]
    topDecreasedTags: TagChanges[]
  }
}

export const EMPTY_COMMUNITY: Community = {
  contentCreatorDecks: [],
  contestWinnerDecks: [],
  featuredFormatDecks: [],
  featuredTagDecks: [],

  topCards: [],
  topCommanders: [],

  contestTitle: null,

  contentCreatorRole: { id: 0, name: '' },
  featuredFormat: { id: 0, name: '' },
  featuredTag: { id: 0, name: '' },

  topCardQuantities: {},
  topCommanderQuantities: {},

  tagChangesOverTime: {
    topWeeklyTags: [],
    topIncreasedTags: [],
    topDecreasedTags: [],
  },
}

export type Props = {
  community: Community
  home: {
    recentDecks: any[]
    news: any[]
    features: any[]
  }
}

const HomePage = ({ home, community }: Props) => (
  <PageWrapper title="MTG Deck Builder - Archidekt" description={DEFAULT_DESCRIPTION} className={styles.container}>
    <Landing />

    <InlineAd banner longBanner id="community-1" removeOnNoAd />

    <Tabs className={styles.containerWidth} />

    <DeckGroup
      title="Recent Decks"
      secondary="View recent decks"
      href="/search/decks"
      decks={home.recentDecks}
      className={styles.containerWidth}
    />

    <TopCardsGrid
      commanders
      title="Trending New Commanders"
      cards={community.topCommanders}
      quantities={community.topCommanderQuantities}
      className={styles.containerWidth}
    />

    <CustomAd />

    <DeckGroup title="Creator Decks" decks={community.contentCreatorDecks} className={styles.containerWidth} />

    <TopCardsGrid
      title="Trending New Cards"
      cards={community.topCards}
      quantities={community.topCardQuantities}
      className={styles.containerWidth}
    />

    <InlineAd banner longBanner billboard id="community-2" removeOnNoAd />

    <DeckGroup
      title={`Featured Deck Tag (${community.featuredTag.name})`}
      secondary="View all decks"
      href={`/tags/${tagNameToUrl(community.featuredTag.name || '')}`}
      decks={community.featuredTagDecks}
      className={styles.containerWidth}
    />

    <div className={`${styles.containerWidth} ${styles.tagInfo}`}>
      <TagInfoTable tagChanges={community.tagChangesOverTime.topWeeklyTags} infoColumn="weekly" />
      <TagInfoTable tagChanges={community.tagChangesOverTime.topIncreasedTags} infoColumn="increase" />
      <TagInfoTable tagChanges={community.tagChangesOverTime.topDecreasedTags} infoColumn="decrease" />
    </div>

    <InlineAd banner longBanner billboard id="community-3" removeOnNoAd />

    <DeckGroup
      title={community.contestTitle || 'Manually Selected Decks'}
      decks={community.contestWinnerDecks}
      className={styles.containerWidth}
      gridClassName={styles.contestDecks}
      includeContestWinnerIcons
    />

    <NewsGroup news={home.news} className={styles.containerWidth} />

    <InlineAd banner longBanner billboard id="community-4" removeOnNoAd />

    <DeckGroup
      title={`Featured Format (${community.featuredFormat.name})`}
      secondary="View all decks"
      href={`/search/decks?formats=${community.featuredFormat.id}&orderBy=-updatedAt`}
      decks={community.featuredFormatDecks}
      className={styles.containerWidth}
    />

    <FeaturesGroup features={home.features} className={styles.containerWidth} />
  </PageWrapper>
)

export const getServerSideProps: GetServerSideProps<Props> = async context => {
  try {
    const home = await new RequestService(context).get(environment.staticHomePageURL, {}, true)
    const community = await new RequestService(context).get(environment.staticCommunityPageURL, {}, true)

    return {
      props: {
        home,
        community: {
          ...community,
          topCards: community.topCards.map(responseToCard),
          topCommanders: community.topCommanders.map(responseToCard),
        },
      },
    }
  } catch (err) {
    return LOG_ERROR_AND_REDIRECT(err, context)
  }
}

export default HomePage
