import styled from 'styled-components'

export const SearchContainer = styled.div`
  input[type='text'] {
    border-radius: 3px !important;
    width: 500px !important;
    @media (max-width: 600px) {
      width: 275px !important;
    }
  }
  .result {
    padding: 12px !important;
    color: ${props => props.theme.color} !important;
  }
`

export const StyledResult = styled.span`
  font-size: 1rem;
`

export const DefaultLabel = styled.div`
  position: absolute;
  right: 5px;
  color: ${props => props.theme.lightColor};
  font-style: italic;
  font-size: 0.75em;
  top: 5px;
`
