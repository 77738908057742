import React from 'react'
import { useCookies } from 'react-cookie'

import Link from 'components/elements/Link'

import styles from './tabs.module.scss'
import { useRouter } from 'next/router'

type Props = {
  className?: string
}

const Tabs = ({ className = '' }: Props) => {
  const router = useRouter()
  const [{ tbId: userId }] = useCookies(['tbId'])

  if (!userId) return null

  return (
    <div className={`${styles.tabs} ${className}`}>
      <Link href="/" className={router.pathname === '/' ? styles.selected : ''}>
        My Stuff
      </Link>
      <Link href="/community" className={router.pathname === '/community' ? styles.selected : ''}>
        Community Stuff
      </Link>
    </div>
  )
}

export default Tabs
