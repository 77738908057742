const commanderNameAliases: Record<string, string> = {}

export const commanderNameToUrl = (tagName: string) => {
  if (commanderNameAliases[tagName]) return commanderNameAliases[tagName]

  return tagName.replace(/ /g, '_').replace(/\/\//g, '__and__')
}

export const urlToCommanderName = (slug: string) => {
  const reversedAliases = Object.fromEntries(Object.entries(commanderNameAliases).map(([k, v]) => [v, k]))

  if (reversedAliases[slug]) return reversedAliases[slug]

  return slug.replace(/__and__/g, '//').replace(/_/g, ' ')
}
