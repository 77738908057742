import React from 'react'
import Icon from 'components/elements/Icon'
import { useCookies } from 'react-cookie'

import MultiSearch from 'components/formElements/MultiSearch'
import Link from 'components/elements/Link'

import styles from './landing.module.scss'

type Props = {}

const art = '/images/archidekt_small.webp'

const Landing = (_props: Props) => {
  const [{ tbId: userId, tbRootFolder: rootFolderId }] = useCookies(['tbId', 'tbRootFolder'])

  return (
    <div className={styles.container}>
      <div className={styles.background} style={{ backgroundImage: `url('${art}')` }} />

      {/* <img className={styles.sketch} alt="sketch1" src="/images/sketch1.svg" style={{ top: '10%', left: '-5%' }} /> */}
      {/* <img className={styles.sketch} alt="sketch6" src="/images/sketch3.svg" style={{ top: '-20%', left: '40%' }} /> */}
      {/* <img className={styles.sketch} alt="sketch7" src="/images/sketch4.svg" style={{ top: '10%', left: '60%' }} /> */}
      {/* <img className={styles.sketch} alt="sketch8" src="/images/sketch6.svg" style={{ top: '30%', left: '10%' }} /> */}

      <div className={styles.topGradientMask} />
      <div className={styles.bottomGradientMask} />

      <div className={styles.content}>
        <div className={styles.controls}>
          <MultiSearch />

          <div className={styles.buttons}>
            {!!Number(userId) && (
              <>
                <Link href="/new-deck">
                  <Icon name="add" /> New deck
                </Link>

                <Link href={`/folders/${rootFolderId}`}>
                  <Icon name="folder outline" /> My folders
                </Link>
              </>
            )}

            {!Number(userId) && (
              <>
                <Link href="/sandbox">
                  <Icon name="flask" /> Deck sandbox
                </Link>

                <Link href="/search/decks">
                  <Icon name="globe" /> All decks
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
