import React from 'react'
import Icon from 'components/elements/Icon'
import { useRouter } from 'next/router'

import { NewsArticleType } from 'types/news'

import Link from 'components/elements/Link'

import { timeSince } from 'utils/time.utils'
import { encodeUsernameRoute } from 'utils/encoding'

import styles from './newsCard.module.scss'

type Props = {
  article: NewsArticleType
}

const TAGS: Record<string, string> = {
  news: '#00a9ff',
  competition: '#fc7f00',
  changelog: '#73c62b',
  content: '#dd52f5',
}

/**
 * This is a card that renders news article information that is displayed both on the home page and the NewsResultPage.
 * Tag color is determined by a constant map for tags that are recognized. The card links to the news article page.
 * The featured image for the article is rendered on the left side while the title, description and info is on the
 * right.
 */
const NewsCard = ({ article }: Props) => {
  const router = useRouter()
  const link = `/news/${article.id}`

  const handleSloppyClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target instanceof HTMLDListElement)) return

    router.push(link)
  }

  return (
    <div className={styles.newsCardContainer} onClick={handleSloppyClick}>
      <Link className={styles.newsImage} href={link} tabIndex={-1}>
        <img src={article.featured} alt={article.title} />
      </Link>
      <div className={styles.newsText}>
        <div>
          <Link className={styles.newsHeader} href={link}>
            {article.title}
          </Link>
          <p className={styles.newsDescription}>{article.description}</p>
        </div>
        <div className={styles.newsMeta}>
          <Link className={styles.newsAuthor} href={`/u/${encodeUsernameRoute(article.owner.username)}`}>
            <>
              <Icon name="user" />
              {article.owner.username}
            </>
          </Link>
          <div className={styles.newsDate}>{timeSince(article.createdAt)}</div>
        </div>
      </div>
      <div className={styles.newsTag} style={{ backgroundColor: TAGS[article.tag] }}>
        {article.tag}
      </div>
    </div>
  )
}

export default NewsCard
